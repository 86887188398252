import _ from 'lodash';
import waRequest from './wa-request';

const cancelablePersonListSingleton = waRequest.createCancelableSingleton();
const cancelablePersonGeoSingleton = waRequest.createCancelableSingleton();
const cancelablePersonLocationRollUpSingleton = waRequest.createCancelableSingleton();
const cancelablePersonLocationSummariesSingleton = waRequest.createCancelableSingleton();

export const baseApi = '/alsat/v2';

function getStandardAlsatPersonsList({page, size, sort, filterParams, locale, includeDetails = true}) {
  const url = `${baseApi}/persons/searches`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    params: {
      page,
      size,
      sort,
      locale,
      includeDetails
    },
    data: filterParams
  });
}

function getAlsatPersonGeodata(
  {
    organizationId,
    zoom,
    dateStart,
    dateEnd,
    locationIds = undefined,
    maxLng,
    maxLat,
    minLng,
    minLat,
    personLocationTypes,
    mobileLocationScope,
    organizationIds = undefined,
    airlineId = undefined,
    airportId = undefined,
    flightNumberLike = undefined,
    addressLabels = undefined,
    addressAccuracies = undefined,
    railwayNameLike = undefined,
    trainNumberLike = undefined
  }
) {

  const url = `${baseApi}/persons/locations/cluster-searches`;

  cancelablePersonGeoSingleton.cancel();

  return cancelablePersonGeoSingleton.requestAuthenticated({
    url: url,
    method: 'post',
    params: {
      organizationId,
      zoom,
      maxLng,
      maxLat,
      minLng,
      minLat
    },
    data: {
      organizationIds: organizationIds || [organizationId],
      dateStart: dateStart,
      dateEnd: dateEnd,
      locationIds: locationIds,
      personLocationTypes: personLocationTypes,
      mobileLocationScope: mobileLocationScope,
      airlineId: airlineId,
      airportId: airportId,
      flightNumberLike: flightNumberLike,
      railwayNameLike: railwayNameLike,
      trainNumberLike: trainNumberLike,
      addressLabels,
      addressAccuracies
    }
  });
}

function getAlsatPersonsLocationsSearches({organizationId, page, size, sort, filterParams, locale}) {
  const url = `${baseApi}/persons/locations/searches`;

  return cancelablePersonListSingleton.requestAuthenticated({
    url: url,
    method: 'post',
    params: {
      page,
      size,
      sort,
      organizationId,
      locale
    },
    data: filterParams
  });
}

function initiatePersonExport(locale, requestParams, filterParams, sort) {

  const {
    accountInfo,
    includeOrganizations,
    includePersonTypes,
    addresses,
    emails,
    emergencyContacts,
    names,
    phones,
    optionalFields,
    mobileLocations,
    tripSegments,
    includeExpatriations,
    includeStopovers,
    includeSites,
    identifiers
  } = requestParams;

  const url = `${baseApi}/persons/exports`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    params: {
      accountInfo,
      includeOrganizations,
      includePersonTypes,
      addresses,
      emails,
      emergencyContacts,
      names,
      phones,
      sort,
      locale,
      optionalFields,
      mobileLocations,
      tripSegments,
      includeExpatriations,
      includeStopovers,
      includeSites,
      identifiers
    },
    data: filterParams
  });
}

function getSiteExportStatusConfigPattern({id, successCallback}) {
  const url = `${baseApi}/persons/exports/${id}`;

  return waRequest.requestWithRetryUntilSuccess({
    url: url,
    method: 'get'
  },
  successCallback
  );
}

function getPersonExportStatus(exportId, locale) {
  const url = `${baseApi}/persons/exports/${exportId}`;

  const requestConfig = {
    url: url,
    method: 'get',
    params: {
      locale
    }
  };

  const successCallback = (result) => {
    const status = _.get(result, 'data.status.id');

    switch (status) {
      case 'COMPLETE':
        return true;
      case 'NEW':
      case 'PROCESSING':
        return false;
      case 'FAILED':
      case 'CANCELED':
      case 'DELETED':
      case 'TOO_LARGE':
      case 'DOWNLOADED':
        throw new Error('Error occurred while generating download');
      default:
        return false;
    }
  };

  return waRequest.requestWithRetryUntilSuccess(requestConfig, successCallback, 2000);
}

function getPersonExportFile(exportId, markDownloaded = true) {
  const url = `${baseApi}/persons/exports/${exportId}/file`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    responseType: 'blob',
    params: {
      markDownloaded
    }
  });
}

/**
 * Request to person search to get list of persons with roll up or their mobile location modalities
 *
 * @param personSearchFilter as defined within https://bitbucket.org/crisis24/wc4-api-docs/src/07bfe93f8260a868b798dcadea23eeac15559f8d/src/docs/private/alsat/wc4-alsat-ms.yaml#lines-1198
 * @param page
 * @param size
 * @param sort
 * @param locale
 * @param includeDetails
 * @returns {*}
 */
function getAlsatPersonsLocationsRollupSearches(
  personSearchFilter,
  {page, size, sort, locale, includeDetails}
) {
  const url = `${baseApi}/persons/locations/rollup-searches`;

  return cancelablePersonLocationRollUpSingleton.requestAuthenticated({
    url: url,
    method: 'post',
    params: {
      page,
      size,
      sort,
      locale,
      includeDetails
    },
    data: personSearchFilter
  });
}

function getAlsatPersonsLocationsRollupSearchesConfigPattern({data, params, signal}) {
  const url = `${baseApi}/persons/locations/rollup-searches`;

  return cancelablePersonLocationRollUpSingleton.requestAuthenticated({
    url: url,
    method: 'post',
    params,
    data,
    signal
  });
}

function getPersonLocationSummaries(personSearchFilter) {
  const url = `${baseApi}/persons/locations/summaries`;

  return cancelablePersonLocationSummariesSingleton.requestAuthenticated({
    url: url,
    method: 'post',
    data: personSearchFilter
  });
}

function getPersonLocationTypes(locale) {
  const url = `${baseApi}/persons/person-location-types`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      locale
    }
  });
}

/**
 * Initiate authenticated POST ${baseApi}/persons/searches
 * use this using the standard config pattern
 *
 * @param {Object} config
 * @param {Object} config.params
 * @param {number} config.params.page Zero-based page index (0..N)
 * @param {number} config.params.size The size of the page to be returned
 * @param config.params.sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
 * @param config.params.includeDetails DEPRECATED, use detailLevel instead
 * @param config.params.locale The requested language to return data in.
 * @param config.params.detailLevel Controls which fields to return.
 * @param {Object} config.data Searching and filtering options for a person
 * @param {AbortSignal} config.signal use to cancel request
 * @returns {Promise}
 */
function getAlsatPersonsListConfigPattern({
  params: {
    page = 0,
    size = 25,
    sort,
    locale,
    includeDetails,
    detailLevel
  },
  signal,
  data
}) {

  return waRequest.requestAuthenticated({
    url: `${baseApi}/persons/searches`,
    method: 'post',
    params: {
      page,
      size,
      sort,
      locale,
      includeDetails,
      detailLevel
    },
    signal,
    data
  });
}

function initiatePersonExportConfigPattern({params, data}) {

  const {
    organizationId,
    locale,
    createUrl,
    sort,
    accountInfo,
    includeOrganizations,
    addresses,
    emails,
    names,
    phones,
    optionalFields,
    mobileLocations,
    includePersonTypes,
    tripSegments,
    includeExpatriations,
    includeSites
  } = params;


  return waRequest.requestAuthenticated({
    url: `${baseApi}/persons/exports`,
    method: 'post',
    params: {
      organizationId,
      locale,
      createUrl,
      sort,
      accountInfo,
      includeOrganizations,
      addresses,
      emails,
      names,
      phones,
      optionalFields,
      mobileLocations,
      includePersonTypes,
      tripSegments,
      includeExpatriations,
      includeSites
    },
    data
  });
}

function getPersonExportStatusConfigPattern({exportId, locale, successCallback}) {

  return waRequest.requestWithRetryUntilSuccess({
    url: `${baseApi}/persons/exports/${exportId}`,
    method: 'get',
    params: {
      locale
    }
  }, successCallback, 2000);
}

function createSavedSearch({data: {dateRangeType, description, filter, name}}) {
  const url = `${baseApi}/persons/saved-searches`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'POST',
    data: {
      dateRangeType,
      description,
      filter,
      name
    }
  })
}

function updateSavedSearch({pathParams: {id}, data: {dateRangeType, description, filter, name}}) {
  const url = `${baseApi}/persons/saved-searches/${id}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'PUT',
    data: {
      dateRangeType,
      description,
      filter,
      name
    }
  })
}

function deleteSavedSearch({pathParams: {id}}) {
  const url = `${baseApi}/persons/saved-searches/${id}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'DELETE'
  });
}

function getSavedSearches({
  params: {
    page = 0,
    size = 25,
  },
  data,
}) {
  const url = `${baseApi}/persons/saved-searches/searches`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'POST',
    params: {
      page,
      size,
    },
    data,
  });
}

function getSavedSearch({pathParams: {id}, signal}) {
  const url = `${baseApi}/persons/saved-searches/${id}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'GET',
    signal
  });
}

function createSavedSearchSchedule({
  pathParams: {searchId},
  data
}) {
  const url = `${baseApi}/persons/saved-searches/${searchId}/schedules`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'POST',
    data
  })
}

function updateSavedSearchSchedule({
  pathParams: {searchId, scheduleId},
  data
}) {
  const url = `${baseApi}/persons/saved-searches/${searchId}/schedules/${scheduleId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'PUT',
    data
  })
}

function deleteSavedSearchSchedule({pathParams: {searchId, scheduleId}}) {
  const url = `${baseApi}/persons/saved-searches/${searchId}/schedules/${scheduleId}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'DELETE'
  });
}

function getSavedSearchSchedule({pathParams: {searchId, id}, signal}) {
  const url = `${baseApi}/persons/saved-searches/${searchId}/schedules/${id}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'GET',
    signal
  });
}

function getPersonFilterFieldValues(config) {

  const {
    pathParams: {
      filterField
    },
    params: {
      size
    },
    data: {
      includeSubOrganizations,
      organizationIds,
      valueLike
    }
  } = config;

  return waRequest.requestAuthenticated({
    url: `${baseApi}/persons/filter-values/${filterField}/searches`,
    method: 'POST',
    params: {
      size
    },
    data: {
      includeSubOrganizations,
      organizationIds,
      valueLike
    }
  });
}

function getCustomPersonFilterFieldValues(config) {

  const {
    pathParams: {
      filterField,
      fieldId
    },
    params: {
      size
    },
    data: {
      includeSubOrganizations,
      organizationIds,
      valueLike
    }
  } = config;

  return waRequest.requestAuthenticated({
    url: `${baseApi}/persons/filter-values/${filterField}/${fieldId}/searches`,
    method: 'POST',
    params: {
      size
    },
    data: {
      includeSubOrganizations,
      organizationIds,
      valueLike
    }
  });
}

export default {
  getAlsatPersonsLocationsSearches,
  getAlsatPersonGeodata,
  initiatePersonExport,
  getPersonExportStatus,
  getPersonExportFile,
  getAlsatPersonsLocationsRollupSearches,
  getPersonLocationTypes,
  getPersonLocationSummaries,
  getStandardAlsatPersonsList,
  getSiteExportStatusConfigPattern,
  getAlsatPersonsListConfigPattern,
  initiatePersonExportConfigPattern,
  getPersonExportStatusConfigPattern,
  createSavedSearch,
  updateSavedSearch,
  deleteSavedSearch,
  getSavedSearches,
  getSavedSearch,
  createSavedSearchSchedule,
  updateSavedSearchSchedule,
  deleteSavedSearchSchedule,
  getSavedSearchSchedule,
  getAlsatPersonsLocationsRollupSearchesConfigPattern,
  getPersonFilterFieldValues,
  getCustomPersonFilterFieldValues
};
