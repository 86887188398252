import waRequest from './wa-request';

export const apiBase = '/sites/v1';

export const personsBase = `${apiBase}/sites/persons`;

function getSiteTypes(locale) {
  const url = `${apiBase}/sites/siteTypes`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    params: {
      locale
    }
  });
}

function addSite({data, signal}) {
  const url = `${apiBase}/sites`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'put',
    data,
    signal
  });
}

function updateSite({data, signal}) {
  return addSite({data, signal});
}

function getSitesSearchList({
  params: {
    locale,
    page,
    size,
    sort
  },
  data: {
    quickSearch,
    nameLike,
    identifierLike,
    siteTypeIds,
    cityNameLike,
    provinceNameLike,
    organizationIds,
    countryNameLike,
    notesLike,
    applyVulnerabilityRadius,
    polygons,
    distanceFromPolygon,
    locationIds,
    cluster,
    siteIds,
    sitePersonTypeFilter,
    personFilter,
    bulkLoadId
  },
  signal
}) {
  const url = `${apiBase}/sites/searches`;

  return waRequest.requestAuthenticated({
    url,
    method: 'post',
    params: {
      locale,
      page,
      size,
      sort
    },
    data: {
      quickSearch,
      nameLike,
      identifierLike,
      siteTypeIds,
      cityNameLike,
      provinceNameLike,
      organizationIds,
      countryNameLike,
      notesLike,
      applyVulnerabilityRadius,
      polygons,
      distanceFromPolygon,
      locationIds,
      cluster,
      siteIds,
      sitePersonTypeFilter,
      personFilter,
      bulkLoadId
    },
    signal
  })
}

function getSitesPropertySearch({pathParams: {propertyName}, params: {startsWith}, signal}) {
  const url = `${apiBase}/sites/properties/${propertyName}`;

  return waRequest.requestAuthenticated({
    url,
    method: 'get',
    signal,
    params: {
      startsWith
    },
  })
}

function initiateSitesExport(configData) {

  const {pathParams, data: {filter}} = configData;

  const {
    organizationId,
    locale,
    createUrl,
    exportFormat,
    sort,
    includeSiteType,
    includeVulnerabilityRadius,
    includeOrganization,
    includeCoordinates,
    includeEmployeeCount,
    includeAddress,
    includeNotes,
    includePOCs,
    page,
    size,
    style
  } = pathParams;

  const url = `${apiBase}/sites/exports`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    params: {
      organizationId,
      locale,
      createUrl,
      exportFormat,
      sort,
      includeSiteType,
      includeVulnerabilityRadius,
      includeOrganization,
      includeCoordinates,
      includeEmployeeCount,
      includeAddress,
      includeNotes,
      includePOCs,
      page,
      size,
      style
    },
    data: filter
  });

}

function getSiteExportStatus({id, successCallback}) {
  const url = `${apiBase}/sites/exports/${id}`;

  return waRequest.requestWithRetryUntilSuccess({
    url: url,
    method: 'get'
  },
  successCallback
  );
}

function getSiteById({pathParams: {siteId}, params: {locale}, signal}) {
  const url = `${apiBase}/sites/${siteId}`;

  return waRequest.requestAuthenticated({
    url,
    method: 'get',
    signal,
    params: {
      locale
    },
  })
}

function updateBulkSites({data: {
  deleted,
  notes,
  organizationId,
  preferences,
  siteTypeId,
  siteIds,
  siteFilter
}}){
  const url = `${apiBase}/sites`;

  return waRequest.requestAuthenticated({
    url,
    method: 'patch',
    data:{
      // patch changes
      deleted,
      notes,
      organizationId,
      preferences,
      siteTypeId,
      // filter of affected items
      siteIds,
      siteFilter
    }
  })
}

function deleteSite({pathParams: {siteId}, signal}) {
  const url = `${apiBase}/sites/${siteId}`;

  return waRequest.requestAuthenticated({
    url,
    method: 'delete',
    signal
  })
}

function updateSitePersonAssociations({params, data}) {
  const url = `${apiBase}/sites/persons`;

  return waRequest.requestAuthenticated({
    url,
    method: 'post',
    params,
    data
  });
}

function deleteSitePersonAssociations({params, data}) {
  const url = `${apiBase}/sites/persons`;

  return waRequest.requestAuthenticated({
    url,
    method: 'post',
    params,
    data: {
      ...data,
      isDelete: true
    }
  });
}

function initiateSitesOccupantsExport(configData) {
  const {pathParams, data: {filter}} = configData;

  const {
    organizationId,
    locale,
    createUrl,
    sort,
    includeSiteType,
    includePhone,
    includeEmail,
    includeOrganization,
    includeCoordinates,
    includeAddress,
    exportFormat
  } = pathParams;

  const url = `${apiBase}/sites/occupants/exports`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    params: {
      organizationId,
      locale,
      createUrl,
      sort,
      includeSiteType,
      includePhone,
      includeEmail,
      includeOrganization,
      includeCoordinates,
      includeAddress,
      exportFormat
    },
    data: filter
  });

}

function initiateSitePointsOfContactExport(configData) {

  const {pathParams: {siteId, ...rest}, data} = configData;

  const {
    organizationId,
    locale,
    createUrl,
    sort
  } = rest;

  const url = `${apiBase}/sites/${siteId}/pocs/exports`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    params: {
      organizationId,
      locale,
      createUrl,
      sort
    },
    data
  });

}

function initiateSiteOccupantsExport(configData) {

  const {pathParams: {siteId, ...rest}, data} = configData;

  const {
    organizationId,
    locale,
    createUrl,
    sort
  } = rest;

  const url = `${apiBase}/sites/${siteId}/occupants/exports`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    params: {
      organizationId,
      locale,
      createUrl,
      sort
    },
    data
  });

}

function getSitesGeodata({params: {locale, zoom}, data, signal}) {

  const url = `${apiBase}/sites/cluster-searches`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    params: {
      locale,
      zoom
    },
    data,
    signal
  });
}

function getSitePersons({
  params: {
    page = 0,
    size = 25,
    sort,
    locale
  },
  data,
  signal
}) {

  return waRequest.requestAuthenticated({
    url: `${personsBase}/searches`,
    method: 'post',
    params: {
      page,
      size,
      sort,
      locale
    },
    data,
    signal
  });
}

function getPersonSites({
  pathParams:{personId},
  params: {
    page = 0,
    size = 25,
    sort,
    locale,
    sitePersonType
  },
  data,
  signal
}) {

  return waRequest.requestAuthenticated({
    url: `${apiBase}/sites/person/${personId}`,
    method: 'get',
    params: {
      page,
      size,
      sort,
      locale,
      sitePersonType
    },
    data,
    signal
  });
}

function getSiteSummaries({
  data: {
    organizationIds,
    polygons,
    locationIds
  },
  signal
}) {
  const url = `${apiBase}/sites/summaries`;

  return waRequest.requestAuthenticated({
    url,
    method: 'post',
    data: {
      organizationIds,
      polygons,
      locationIds
    },
    signal
  })
}

/**
 * Get list of distinct persons associated to sites
 *
 * @param page
 * @param size
 * @param sort
 * @param locale
 * @param data
 * @param signal
 * @returns {Promise}
 */
function getSitesDistinctPersons({
  params: {
    page = 0,
    size = 25,
    sort,
    locale
  },
  data,
  signal
}) {

  return waRequest.requestAuthenticated({
    url: `${personsBase}/distinctPersonSearches`,
    method: 'post',
    params: {
      page,
      size,
      sort,
      locale
    },
    data,
    signal
  });
}

function getBulkUploadsList({
  params: {size = 25, page = 0, sort='createdAt,desc'},
  data: {organizationIds},
  signal
}) {
  const url = `${apiBase}/bulk-loads/searches`;
  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    signal,
    params: {
      size,
      page,
      sort
    },
    data: {
      organizationIds
    }
  });
}

function getBulkUploadsTemplates(params) {
  const url = `${apiBase}/bulk-loads/templates`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    responseType: 'blob',
    params
  });
}

function uploadFile({params, data}) {
  const url = `${apiBase}/bulk-loads`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    params,
    data
  });
}

function getFileDetails(id, successCallback) {
  const url = `${apiBase}/bulk-loads/${id}`;

  return waRequest.requestWithRetryUntilSuccess({
    url: url,
    method: 'get'
  },
  successCallback,
  5000
  );

}

function getFileErrors({pathParams: {fileId}}) {
  const url = `${apiBase}/bulk-loads/${fileId}/errors`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    responseType: 'blob'
  });
}

function getSiteDataFeeds(config) {
  const {
    params:{organizationId},
    signal
  } = config;

  return waRequest.requestAuthenticated({
    url: `${apiBase}/site-data-feeds`,
    method: 'get',
    params: {
      organizationId
    },
    signal
  });
}

function createDataFeed(config){

  const {
    data: {
      clientId,
      fileType,
      mapping,
      name,
      newSiteOrganizationId,
      notification,
      updateAction,
      enabled,
      connection,
      mappingEnabled,
    }
  } = config;

  const url = `${apiBase}/site-data-feeds`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'POST',
    data: {
      clientId,
      fileType,
      mapping,
      name,
      newSiteOrganizationId,
      notification,
      updateAction,
      enabled,
      connection,
      mappingEnabled,
    }
  });
}

function updateDataFeed(config){

  const {
    pathParams: {id},
    data: {
      clientId,
      connection,
      enabled,
      fileType,
      mapping,
      name,
      newSiteOrganizationId,
      notification,
      updateAction
    }
  } = config;

  const url = `${apiBase}/site-data-feeds/${id}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'PUT',
    data: {
      clientId,
      connection,
      enabled,
      fileType,
      mapping,
      name,
      newSiteOrganizationId,
      notification,
      updateAction,
      // mappingEnabled
    }
  });
}

function deleteDataFeed(config){

  const {pathParams: {id}} = config;

  const url = `${apiBase}/site-data-feeds/${id}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'DELETE'
  });
}

function testConnectionDataFeed({pathParams: {id}}){
  const url = `${apiBase}/site-data-feeds/${id}/connection-status`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'GET'
  });
}

function generateSshKeyPair(){
  const url = `${apiBase}/site-data-feeds/ssh-keys`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post'
  });
}

function getSiteDataFeedsInfo(){
  const url = `${apiBase}/site-data-feeds/info`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'GET'
  });
}

function getDataFeed({pathParams: {id}, signal}){
  const url = `${apiBase}/site-data-feeds/${id}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'GET',
    signal
  });
}

function getSiteFilesOnServer(config) {

  const {
    pathParams: {id}
  } = config;

  const url = `${apiBase}/site-data-feeds/${id}/server-files`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get'
  });
}

function getSitesFileOnServerUploadRawFile(config) {

  const {
    pathParams: {
      id,
      fileName
    }
  } = config;

  const url = `${apiBase}/site-data-feeds/${id}/server-files/${fileName}/raw`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    responseType: 'blob'
  });

}

function deleteSitesFileOnServerFile(config){

  const {
    pathParams: {
      id,
      fileName
    }
  } = config;

  const url = `${apiBase}/site-data-feeds/${id}/server-files/${fileName}`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'DELETE'
  });
}

/**
 * Endpoint to search for bulkLoads.
 *
 * @param {Object} config
 * @param {Object} config.params
 * @param {number} [config.params.page=0] Zero-based page index
 * @param {number} [config.params.size=100] The size of the page to be returned
 * @param {string} config.params.sort Sorting criteria in the format: field(,asc|desc)
 * @param {Object} config.data
 * @param {string[]} [config.data.organizationIds] Organization Ids to filter bulk load results on
 * @param {string} [config.data.siteDataFeedConfigurationId] A site data feed configuration id to filter bulk load results on. Only supported when the source is SFTP.
 * @param {string[]} [config.data.sources] (["MANUAL", "SFTP"] or ["TEST"]) Bulk load source to filter on.
 * */
function searchSitesDataFeedUploads(config) {
  const {
    params,
    data
  } = config;

  const url = `${apiBase}/bulk-loads/searches`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'POST',
    params,
    data
  });

}

/**
 * Initiate authenticated GET Data Feed Bulk Upload raw file to ${apiBase}/bulk-loads/${fileId}/raw
 *
 * @param {Object} config
 * @param {Object} config.pathParams
 * @param {string} config.pathParams.fileId The UUID of the bulk upload
 * @returns {Promise}
 */
function getDataFeedUploadRawFile(config) {
  const {
    pathParams: {
      fileId
    }
  } = config;

  const url = `${apiBase}/bulk-loads/${fileId}/raw`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'get',
    responseType: 'blob'
  });

}

/**
 *
 * Posts a manual or test file through the sftp config. Creates a bulk load associated to and with the sftp configs settings.
 *
 * @param {Object} config
 * @param {Object} config.pathParams
 * @param {string} config.pathParams.dataFeedId uuid
 * @param {Object} config.data
 * @param {boolean} config.data.isTest Whether this is a load file (wont commit sites).
 * @param {string<binary>} config.data.file file to upload
 *
 * @returns {Promise}
 * */
function uploadManualBulkLoadSiteData(config) {

  const {
    pathParams: {
      dataFeedId
    },
    data
  } = config;

  const url = `${apiBase}/site-data-feeds/${dataFeedId}/bulk-loads`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post',
    data
  });
}

function generatePgpKeyPair(){
  const url = `${apiBase}/site-data-feeds/pgp-keys`;

  return waRequest.requestAuthenticated({
    url: url,
    method: 'post'
  });
}

export default {
  addSite,
  updateSite,
  deleteSite,
  getSitesPropertySearch,
  getSitesSearchList,
  getSiteTypes,
  getSiteById,
  updateBulkSites,
  initiateSitesExport,
  getSiteExportStatus,
  updateSitePersonAssociations,
  deleteSitePersonAssociations,
  initiateSitesOccupantsExport,
  initiateSitePointsOfContactExport,
  initiateSiteOccupantsExport,
  getSitesGeodata,
  getSitePersons,
  getPersonSites,
  getSiteSummaries,
  getSitesDistinctPersons,
  getBulkUploadsList,
  getBulkUploadsTemplates,
  uploadFile,
  getFileDetails,
  getFileErrors,
  getSiteDataFeeds,
  createDataFeed,
  updateDataFeed,
  deleteDataFeed,
  testConnectionDataFeed,
  generateSshKeyPair,
  getSiteDataFeedsInfo,
  getDataFeed,
  getSiteFilesOnServer,
  getSitesFileOnServerUploadRawFile,
  deleteSitesFileOnServerFile,
  searchSitesDataFeedUploads,
  getDataFeedUploadRawFile,
  uploadManualBulkLoadSiteData,
  generatePgpKeyPair
};
